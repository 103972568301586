<template>
  <div class="px-4">
    <v-form class="mt-3">
      <v-card class="pa-4 mx-auto">
        <h2 class="secondary pa-3">CPMC INFORMATION</h2>
        <div class="mt-3">
          <b>Ref No:</b>
          <v-select
            :items="cdps_cpmc"
            @change="getCurrentRefData(dform.CPMCRefNo.id)"
            v-model.trim="dform.CPMCRefNo"
            item-text="CDPRef"
            item-value="CDPRef"
            return-object
            outlined
            dense
          ></v-select>
        </div>
        <div v-if="dform.CPMCRefNo">
          <v-row>
            <v-col cols="12" sm="6">
              <b>CPMC/GPMC Election Date:</b>
              <v-text-field
                v-model.number="dform.ElectionDate"
                readonly
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <b>CPMC/GPMC Training Date:</b>
              <v-text-field
                v-model.number="dform.TrainingDate"
                readonly
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div>
            <b>Type of Committee:</b>
            <v-select
              v-model.trim="dform.Committee"
              :items="committees"
              outlined
              dense
            ></v-select>
          </div>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <b>No of CPMC/GrPM:</b>
              <v-text-field
                v-model.number="dform.NumberOfCpmc"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>No of Male:</b>
              <v-text-field
                v-model.number="dform.CPMCMaleNo"
                type="number"
                outlined
                @change="calculateMale"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Percentage of Male (%):</b>
              <v-text-field
                v-model="dform.PercentOfMale"
                type="number"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>No of Female:</b>
              <v-text-field
                v-model.number="dform.CPMCFemaleNo"
                @change="calculateFemale"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Percentage of Female (%):</b>
              <v-text-field
                v-model="dform.PercentOfFemale"
                type="number"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Vulnerability:</b>
              <v-text-field
                v-model.trim="dform.Vulnerability"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <b>Registration Date at Local Government:</b>
              <v-text-field
                v-model.trim="dform.RegDate"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <b>No of Trained CPMC/GrPMC:</b>
              <v-text-field
                v-model.trim="dform.CPMCTrained"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <b>List of CPMC/GPMC:</b>
              <v-file-input
                placeholder="Upload file in excel format"
                accept=".xls, .xlsx"
                prepend-icon="mdi-file"
                v-if="!dform.CPMCLists || edit.CPMCLists"
                @change="handleCpmclists"
                outlined
              ></v-file-input>

              <div class="text-center" v-else>
                <b>List of CPMC/GPMC: </b>
                <v-icon size="4rem">mdi-file</v-icon>
                <v-btn
                  color="primary"
                  small
                  shaped
                  @click="edit.CPMCLists = true"
                  class="mr-1"
                  >change file</v-btn
                >
              </div>
              <div class="text-center" v-if="edit.CPMCLists">
                <v-btn
                  color="primary"
                  small
                  shaped
                  @click="edit.CPMCLists = false"
                  >cancel change file</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <p v-if="id" class="text-end">
            <v-btn :loading="loading" @click="saveChanges" color="success"
              >Save Changes</v-btn
            >
          </p>
          <p v-else class="text-end">
            <v-btn @click="post" :loading="loading" color="success"
              >Submit</v-btn
            >
          </p>
        </div>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "CommunityInfo",
  mixins: [snackMixin, editUserMixin],

  data() {
    return {
      id: false,
      cdps_cpmc: [],
      dform: {},
      edit: {
        CPMCLists: false,
      },
    };
  },

  async created() {
    await apiClient.get("/cdps_info?u=2").then((res) => {
      if (Array.isArray(res.data)) {
        this.cdps_cpmc = res.data;
      }
    });
    // .catch((err) => this.displayMsg(err.message, "error"));

    if (this.editUser) {
      this.id = true;
    }
  },
  computed: {
    ...mapState(["committees"]),
    ...mapGetters(["getCurUser"]),
  },
  methods: {
    calculateFemale() {
      this.dform.PercentOfFemale =
        (this.dform.CPMCFemaleNo / this.dform.NumberOfCpmc) * 100;
    },
    calculateMale() {
      this.dform.PercentOfMale =
        (this.dform.CPMCMaleNo / this.dform.NumberOfCpmc) * 100;
    },
    async getCurrentRefData(refNo) {
      await apiClient
        .get("/cpmc_info?cdps_id=" + refNo)
        .then((res) => {
          if (Array.isArray(res.data)) {
            this.dform = res.data[0];
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err.message, "error"));
    },

    post() {
      this.loading = true;
      this.dform.type = "cpmcs";
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/cpmc_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.loading = false;
            this.displayMsg("CPMC submitted successfully");
            this.dform = {};
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    handleCpmclists(file) {
      this.processFile(file, "CPMCLists", "Cpmc Lists saved successfully");
    },

    processFile(file, filename) {
      if (!file) {
        return;
      }
      const validFile =
        file.name.includes(".xls") || file.name.includes(".xlsx");
      if (!validFile) {
        this.displayMsg(
          "Invalid file uploaded! Please upload excel file",
          "error"
        );
        return;
      }
      let fd = new FormData();
      let data = {
        id: this.dform.id,
        filename: this.dform[filename],
      };
      fd.append("file", file);
      fd.append("data", JSON.stringify(data));
      let config = { header: { "Content-Type": "multipart/form-data" } };

      apiClient
        .post("/process_cpmclist", fd, config)
        .then((res) => {
          if (res.data.filename) {
            this.dform[filename] = res.data.filename;
            this.edit.CPMCLists = false;
            this.displayMsg("Excel file uploaded succesfully");
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },

    saveChanges() {
      this.dform.type = "cpmcs";
      this.dform.modified_by = this.getCurUser;
      this.loading = true;
      apiClient
        .put("/cpmc_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            this.id = "";
            setTimeout(() => {
              this.$router.push({
                name: "CPMCInformationTable",
              });
            }, 3000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>




